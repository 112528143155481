<template>
  <span v-if="visible" v-show="show" class="lazy-component">
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: {
    if: Boolean,
    show: Boolean
  },
  data() {
    return {
      visible: false
    }
  },
  watch: {
    if: {
      handler(n) {
        this.visible = n
      },
      immediate: true
    },
    show: {
      handler(n) {
        if (n) this.visible = true
      },
      immediate: true
    }
  }
}
</script>
